import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql } from "gatsby";
import Lightbox from "../components/lightBox";
import SEO from "../components/seo";

export const query = graphql`
  query {
    szansy: file(relativePath: { eq: "system-dla-agencji-reklamowej.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kalendarz: file(relativePath: { eq: "kalendarz.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pulpit: file(relativePath: { eq: "planowanie-pracy-pulpit.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const KoordynacjaPracy = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Koordynacja pracy zespołu"
      headline="Wyższy poziom komunikacji"
    >
      <SEO
        title="Koordynacja pracy zespołu"
        description="Sprawna koordynacja pracy zespołu w agencji reklamowej i marketingowej ✅ Współdzielone kalendarze • Własne typy zadań i zdarzeń • Historia aktywności powiazana ze zleceniem • Zapytaj o bezpłatne demo!"
        keywords={["planowanie pracy"]}
      />
      <p>
        Skuteczna praca <strong>agencji reklamowej</strong> wymaga{" "}
        <strong>odpowiedniej koordynacji</strong>, ponieważ w większości zleceń
        bierze udział <strong>wielu pracowników z różnych działów</strong>.
        Niezależnie, czy chodzi o{" "}
        <strong>
          zaprojektowanie, zaplanowanie i umieszczenie reklamy w mediach
        </strong>{" "}
        czy tworzenie stoisk reklamowych, jest to z reguły praca zespołu.
        Empireo pomoże Ci{" "}
        <strong>
          skoordynować te przedsięwzięcia i optymalnie wykorzystać czas
        </strong>{" "}
        Twoich pracowników.
      </p>

      <br />
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.kalendarz, data.pulpit]}
        alts={[
          "Koordynacja pracy zespołu w agencji reklamowej, Współdzielone kalendarze w agencji reklamowej, Zarządzanie zadaniami w agencji reklamowej i marketingowej",
        ]}
      />
      <h3>Kluczowe możliwości:</h3>
      <p>
        <ul>
          <li>
            <strong>Zadania i zdarzenia</strong> - czyli podstawowe narzędzie do
            koordynowania pracy, wraz z szeregiem przydatnych możliwości.
            Empireo pomoże Ci zaplanować przyszłość (zadania) oraz analizować
            przeszłość (zdarzenia). Możesz je dowolnie wiązać z pracownikami,
            kontrahentami, dokumentami i procesami.
          </li>
          <li>
            <strong>Tworzenie własnych typów zadań</strong> - ponieważ w tak
            kreatywnej branży potrzeby Twojej agencji mogą być unikatowe oraz
            szybko ewoluować.
          </li>
          <li>
            <strong>Kalendarz</strong> - czyli narzędzie do łatwej koordynacji
            pracy Twojej i Twoich pracowników. Umożliwia podgląd bieżących zadań
            i delegację nowych oraz gwarantuje, że nikt o niczym nie zapomni.
          </li>
        </ul>
      </p>
      <p
        style={{
          marginTop: "30px",
        }}
      >
        {" "}
        Dzięki Empireo i zaimplementowanym w nim zadaniom i zdarzeniom utworzysz{" "}
        <strong>
          jasny, spójny plan działania dla wszystkich pracowników{" "}
        </strong>{" "}
        Twojej agencji reklamowej. Na <strong>współdzielonym kalendarzu</strong>{" "}
        szybko sprawdzisz dostępność poszczególnych członków zespołu. Zdarzenia
        ułożą się w <strong>czytelną historię współpracy z klientem</strong>, w
        której łatwo odnajdziesz poczynione z nim wcześniej ustalenia.
      </p>
    </FunctionPageLayout>
  );
};

export default KoordynacjaPracy;
